<template>

  <form @submit="checkForm" name="uploadForm" id="uploadForm">

    <transition name="fade" mode="out-in">

      <div v-if="messages">

        <check-login></check-login>
        <nav-tabs :active="'Upload'"></nav-tabs>

        <h2 v-html="messages['fh.nav.dateiupload']"></h2>

        <div class="alert alert-info" role="alert" v-if="bewerbergruppe === 'AUSSERORDENTLICHER_HOERER'">
          {{ messages['fh.fileUpload.aoHoerer'] }}
        </div>

        <hr class="mt-5 mb-5">

        <transition name="fade" mode="out-in">
          <div v-if="studiengaenge && studiengaenge.hauptStudiengang">

            <h3 class="mb-3">{{ messages['fh.studiengang.hauptbewerbung'] }}</h3>

            <!-- Uploads für die Hauptbewerbung -->
            <studiengang-upload
              :studiengang="studiengaenge.hauptStudiengang"
              :new-files-uploaded-clear="newFilesUploadedClear"
              :reload="reload"
              v-on:new-files-uploaded="setNewFilesUploaded"
            />

            <!-- Uploads für die Nebenbewerbung -->
            <transition name="fade" mode="out-in">
              <div v-if="studiengaenge.nebenStudiengaenge && studiengaenge.nebenStudiengaenge.length > 0">

                <hr class="mt-5">
                <h3 class="mb-3">{{ messages['fh.studiengang.weitereBewerbung'] }}</h3>
                <span v-for="studiengang in studiengaenge.nebenStudiengaenge" v-bind:key="studiengang.id">

                  <studiengang-upload
                    :studiengang="studiengang"
                    :reload="reload"
                    :new-files-uploaded-clear="newFilesUploadedClear"
                    v-on:new-files-uploaded="setNewFilesUploaded"
                  />

                </span>

              </div>
            </transition>

            <hr/>

            <div class="form-group row">
              <div class="col-md-10 offset-col-md-2">
                <button type="button"
                        v-on:click="submitNewFiles()"
                        v-if="newFilesUploaded"
                        class="button primary red"
                        :disabled="!formValid">{{ messages['fh.fileUpload.newFilesUploaded'] }}</button>
                <button type="button"
                        v-on:click="next()"
                        v-else-if="abgeschlossen"
                        class="button primary">{{ messages['default.button.next.label'] }}</button>
                <button type="submit"
                        v-else
                        class="button primary"
                        :disabled="!formValid">{{ messages['default.button.forward.label'] }}</button>
              </div>
            </div>
          </div>
        </transition>
        <div v-if="!studiengaenge || !studiengaenge.hauptStudiengang">
          <!-- Fehlermeldung ausgeben falls noch keine Studiengänge gewählt wurden -->
          <error-message :errormessage="messages['fh.studiengang.select']"></error-message>
        </div>

      </div>

    </transition>

  </form>

</template>

<script>

import axios from 'axios';
import NavTabs from './layout/NavTabs.vue';
import bootbox from 'bootbox';
import CheckLogin from './login/CheckLogin.vue';
import StudiengangUpload from './upload/StudiengangUpload.vue';
import ErrorMessage from './layout/ErrorMessage.vue';
// Import the EventBus.
import { EventBus } from '../event-bus.js';
axios.defaults.withCredentials = true;

export default {

  name: 'Upload',
  data () {
    return {
      messages: null,
      files: [],
      bewerbergruppe: null,
      requiredDocuments: null,
      abgeschlossen: null,
      /** ausgewählte Studiengänge des Bewerbers */
      studiengaenge: null,
      /** nach einem Abschluß wurden noch weitere Files hochgeladen */
      newFilesUploaded: null,
      /** wenn auf true gesetzt wird, dann löschen Buttons ausblenden */
      newFilesUploadedClear: 0,
      /** Bewerber hat MMA als Studiengang, weiter zu MMA upload */
      isMMA: null,
      /** Files der Studiengänge neu laden */
      reload: null,
    }
  },
  mounted: function () {

    this.init();

  },
  components: {
    NavTabs,
    CheckLogin,
    StudiengangUpload,
    ErrorMessage
  },
  watch: {
    files: function() {
      // checkIfAllRequiredFilesAvailable muss ausgelöst werden
      this.$forceUpdate();
    }
  },
  computed: {

    /** prüft ob alle Files hochgeladen wurden die nicht optional sind */
    checkIfAllRequiredFilesAvailable: function () {

      if (!this.requiredDocuments || this.requiredDocuments.length < 1) return true;

      for (var i = 0; i < this.requiredDocuments.length; i++) {

        if (this.requiredDocuments[i].id !== 'DIVERSES') {

          if (!this.isUploaded(this.requiredDocuments[i])) return false;

          // prüfe ob ein Dokument doppelt hochgeladen wurde:
          if (this.isDoppelt(this.requiredDocuments[i])) return false;

        }

      }

      return true;

    },
    /**
     * prüfe ob die Formulardaten korrekt erfasst wurden
     */
    formValid: function () {

      if (!this.files) return false
      for (var i = 0; i < this.files.length; i++) {

        // schau ob alle Dokumenttypen ausgewählt wurden
        /* Dokumente können auch später noch ergänzt werden
        if (!this.files[i].dokumenttyp || this.files[i].dokumenttyp === 'null') return false;
        */
        if (!this.files[i].dokumenttyp || this.files[i].dokumenttyp === 'null') continue;

        // schau ob alle Bezeichnungen eingegeben wurden
        if (this.files[i].dokumenttyp.id === 'DIVERSES' &&
            (!this.files[i].bezeichnung || this.files[i].bezeichnung.length < 3)) {
          return false;
        }

      }

      // checke ob alle erforderlichen Files vorhanden sind:
      /* if (!this.checkIfAllRequiredFilesAvailable) return false; */

      return true;

    }

  },
  methods: {

    /** starte übermitteln der Files an die FH */
    submitNewFiles: function () {

      this.showSpinner()
      const that   = this
      const url    = process.env.VUE_APP_SERVER_URL + '/file/submitToFh'
      const params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(response => {

          that.hideSpinner()
          if (response.data.error) {

            bootbox.alert({
              message: that.messages['fh.fileUpload.newFilesUploaded.error'],
              backdrop: true
            })

          } else {

            bootbox.alert({
              message: that.messages['fh.fileUpload.newFilesUploaded.success'],
              backdrop: true
            })

            console.log('uploaded files to oracle', response.data)
            that.newFilesUploaded = null
            that.newFilesUploadedClear = that.newFilesUploadedClear + 1
            let newlist = []
            for (let i = 0; i < that.files.length; i++) {
              for (let j = 0; j < response.data.length; j++) {
                if (response.data[j].id == that.files[i].id) {
                  that.files[i] = response.data[j]
                  break
                }
              }
              newlist.push(that.files[i])
            }
            that.files = newlist

            if (!that.reload) that.reload = 1
            else that.reload++

          }

      })

    },
    /** neue Files für den Upload wurden hochgeladen, zeige den Button zum übermitteln an die FH */
    setNewFilesUploaded: function (val) {
      this.newFilesUploaded = val;
    },
    /**
     * prüft ob ein Dokument bereits hochgeladen wurde
     */
    isUploaded: function (dokument) {

      for (var i = 0; i < this.files.length; i++) {

        if (this.files[i].dokumenttyp && this.files[i].dokumenttyp.id === dokument.id) {
          return true;
        }

      }

      return false;

    },
    isDoppelt: function (dokument) {

      var count = 0;
      for (var i = 0; i < this.files.length; i++) {

        if (this.files[i].dokumenttyp && this.files[i].dokumenttyp.id === dokument.id) {
          count++
        }
        if (count > 1) return true;

      }

      return false;

    },
    isBezeichnungValid: function (file) {

      if (!file.bezeichnung || file.bezeichnung.length < 3) {
        return false;
      }
      return true;

    },
    /** eine neue Datei wurde hochgeladen, zur Liste der Files hinzufügen */
    setFile: function (val) {

      console.log('neuer File hingefügt: ' + val.id);
      this.files.push(val);

    },
    init: function () {

      this.fetchMessages();
      // Listen for the set-language event on EventBus
      EventBus.$on('set-language', lang => {
        this.fetchMessages(lang);
        // hole die erforderlichen Dokumente neu
        this.getRequiredDocuments();
      });

      var that = this

      // hole Studiengänge des Bewerbers:
      var url    = process.env.VUE_APP_SERVER_URL + '/studiengang/findStudiengaengeOfStudent';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

          that.studiengaenge = response.data;

      })

      // prüfe ob Bewerbung bereits abgeschickt wurde
      this.isAbgeschlossen(function(res) {
        that.abgeschlossen = res;
      });

      this.getFilesOfStudent()

      // prüfe Medien Upload:
      url        = process.env.VUE_APP_SERVER_URL + '/studiengang/checkMMA';
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

        if (response.data && (response.data.isHauptStudiengangMMA || response.data.isNebenStudiengangMMA)) {
          that.isMMA    = true;
          that.mmaValid = that.active === 'UploadMedien' ? '' : that.red;
          if (response.data.isNebenStudiengangMMAUploaded || response.data.isHauptStudiengangMMAUploaded) {
            that.mmaValid = that.active === 'UploadMedien' ? '' : that.green;
          }
        } else {
          that.isMMA = false;
        }

      });

      this.getBewerbergruppe(function(result) {

        that.bewerbergruppe = result;

      });

      // hole die erforderlichen Dokumente zu dieser Bewerbergruppe:
      this.getRequiredDocuments();

    },
    getFilesOfStudent: function () {

      // hole bestehende Files des Studenten
      this.files = null
      const that = this
      const url = process.env.VUE_APP_SERVER_URL + '/file'
      var params = {
        bewerbung_id: this.getBewerbung()
      }
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(response => {

        that.files = response.data
        // prüfe ob einer dieser Files noch nicht an Oracle übertragen wurde
        if (that.files && that.abgeschlossen) {
          for (var i = 0; i < that.files.length; i++) {
            if (that.files[i].oracleId == null) {
              that.newFilesUploaded = true
            }
          }
        }

      })

    },
    /** holt die erforderlichen Dokumente der Bewerbergruppe */
    getRequiredDocuments: function () {

      if (this.bewerbergruppe) {

        var that = this;
        var url = process.env.VUE_APP_SERVER_URL + '/file/getRequiredDocuments';
        var params = {
          bewerbergruppe: this.bewerbergruppe,
          bewerbung_id: this.getBewerbung()
        };

        axios({
          method: 'get',
          url: url,
          params: params,
          withCredentials: true
        })
          .then(function (response) {

            that.requiredDocuments = response.data;

          });

      }

    },

    /** Formulardaten prüfen und Form absenden */
    checkForm: function (e) {

      e.preventDefault();
      this.next();

    },
    next: function () {

      // wenn es MedienUploads gibt, dann als nächstes diese
      if (this.isMMA) {
        this.$router.push('UploadMedien');
        return
      }

      // // für Studenten weiter zur Umfrage
      // if (this.bewerbergruppe === 'STUDENT') {
      //   // this.$router.push('Umfrage');
      //   this.$router.push('Datenschutz')
      //   return
      // }

      // weiter zum Datenschutz
      this.$router.push('Datenschutz');

    },
    /** holt die i18n Messages vom Server */
    fetchMessages: function(language) {

      var messages = [
        'fh.nav.dateiupload',
        'default.button.forward.label',
        'fh.fileUpload.unterlagen',
        'fh.fileUpload.remove',
        'fh.fileUpload.add',
        'default.button.close.label',
        'fh.fileUpload.bezeichnung.insert',
        'fh.fileUpload.bezeichnung',
        'fh.fileUpload.remove.sure',
        'fh.fileUpload.erforderlicheDokumente',
        'default.pleaseSelect',
        'fh.fileUpload.doppelt',
        'fh.studiengang.hauptbewerbung',
        'fh.studiengang.weitereBewerbung',
        'fh.nav.dateiupload.title',
        'fh.studiengang.select',
        'fh.fileUpload.newFilesUploaded',
        'fh.fileUpload.newFilesUploaded.success',
        'fh.fileUpload.newFilesUploaded.error',
        'default.button.next.label',
        'fh.fileUpload.aoHoerer'
      ];
      var that = this;
      this.getMessages(language, messages, function(resp) {
        that.messages = resp;
        // Seiten Titel setzen:
        document.title = that.messages['fh.nav.dateiupload.title'];
      });

    }

  }

}
</script>
